type Content = {
    element: HTMLElement;
    height: number;
    open: boolean;
};

/**
 * This class handles the topNav elements to display opening-hours and emergencies.
 * Because we don't just want the information to pop up on top of the rest of the side we want to
 * transition the vertical offset by the height of the element that will be displayed.
 */
export class TopNavHandler {
    private header: HTMLElement;
    private main: HTMLElement;
    private navItems: NodeListOf<HTMLElement>;
    private topNav: HTMLElement;
    private topBlock: HTMLElement;
    private topContents: Map<string, Content> = new Map();
    private resizeObservers: Map<string, ResizeObserver> = new Map();

    public constructor() {
        this.header = document.querySelector(".header");
        this.main = document.querySelector("#Main");
        this.navItems = this.header.querySelectorAll(".nav-item");
        this.topNav = this.header.querySelector(".top-nav");
        this.topBlock = this.header.querySelector(".top-block");

        // initialize the top-content elements to get initial height, state and HTMLElement
        // at the end of the function the variables are set
        this.initTopContent();

        // initialize the nav-items buttons to enabled click events
        this.initNavItems();
    }

    /**
     * This functions initializes all navItems with a click event. In case the event is triggered, all navItems that
     * have not been clicked are deactivated. The clicked elements active class is toggled and the top-content mapping
     * is updated to reflect the current state. Then the Infoblocks are toggled
     */
    private initNavItems() {
        this.navItems.forEach((navItem) => {
            navItem.addEventListener("click", () => {
                this.navItems.forEach((otherNavItem) => {
                    if (navItem !== otherNavItem) {
                        otherNavItem.classList.remove("active");
                    }
                });

                const isActive = navItem.classList.toggle("active");
                this.topNav.classList.toggle("open", isActive);

                if (navItem.classList.contains("open-hours")) {
                    this.setContentOpenState("open-hours", "emergency", isActive);
                } else {
                    this.setContentOpenState("emergency", "open-hours", isActive);
                }
                this.toggleInfoBlock(isActive);
            });
        });
    }

    /**
     * Sets the `open` variable of the top-content elements
     * @param clicked
     * @param notClicked
     * @param isActive
     * @private
     */
    private setContentOpenState(clicked: string, notClicked: string, isActive: boolean) {
        const clickedContent = this.topContents.get(clicked);
        const notClickedContent = this.topContents.get(notClicked);

        clickedContent.open = isActive;
        notClickedContent.open = false;

        this.topContents.set(clicked, clickedContent);
        this.topContents.set(notClicked, notClickedContent);
    }

    /**
     * Initializes the top-content mapping.
     * The key for the mapping is the class that is not 'top-content' but with the '-content' at the end removed
     * Which leaves either "open-hours" or "emergency" as key. This makes other code more readable because
     * the two nav-item classes are "open-hours" and "emergency".
     * @private
     */
    private initTopContent() {
        const topContentBlocks = <NodeListOf<HTMLElement>>this.header.querySelectorAll(".top-content");
        topContentBlocks.forEach((block) => {
            const key = Array.from(block.classList).filter((entry) => entry !== "top-content");
            if (key.length > 0) {
                const accessKey = key[0].replace("-content", "");
                this.topContents.set(accessKey, {
                    element: block,
                    height: block.getBoundingClientRect().height,
                    open: false,
                });
                if (!this.resizeObservers.has(accessKey)) {
                    const observer = new ResizeObserver(this.initTopContent.bind(this));
                    observer.observe(block);
                    this.resizeObservers.set(accessKey, observer);
                }
            }
        });
        this.setBlockHeight();
    }

    /**
     * This function gets the current active top-content element and reads the total height of the topBlock element
     * In case there is no active top-content the whole element is translated out of bounds otherwise activeHeight
     * is set to the active top-content element height and inactiveHeight is set to totalHeight - activeHeight
     * It's also necessary to add the padding to the main div to not have the top-block on top of the main content.
     * @private
     */
    private setBlockHeight() {
        const activeKey = this.getActiveTopContent();
        const totalHeight = this.topBlock.getBoundingClientRect().height;
        let inactiveHeight = 0;
        let activeHeight = 0;
        if (activeKey === "") {
            inactiveHeight = totalHeight;
        } else {
            activeHeight = this.topContents.get(activeKey).height;
            inactiveHeight = totalHeight - activeHeight;
        }
        const mainHeaderPadding = 130;
        this.header.style.setProperty("--top-block-height", inactiveHeight.toString());
        this.main.style.setProperty("--header-height", `${activeHeight + mainHeaderPadding}px`);
    }

    /**
     * This function sets the reverse class on the top-block element
     * This is done because the reverse class set flex-direction: column-reverse and thus switches the order
     * of the top-content blocks ensuring that always the current active element is on the bottom and with that
     * visible
     * @private
     */
    private setTopContentOrder() {
        const activeKey = this.getActiveTopContent();
        if (activeKey === "open-hours") {
            this.topBlock.classList.add("reverse");
        } else if (activeKey === "emergency") {
            this.topBlock.classList.remove("reverse");
        }
    }

    private getActiveTopContent(): string {
        let activeContent = "";
        this.topContents.forEach((value, key) => {
            if (value.open) {
                activeContent = key;
            }
        });
        return activeContent;
    }

    private toggleInfoBlock(isActive: boolean) {
        this.setBlockHeight();
        if (isActive) {
            this.setTopContentOrder();
        }
    }
}
