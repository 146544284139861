export default class MilestoneSection {
    private datePageButtons;
    private readonly items;
    private currentIndex = 0;

    constructor(private container: HTMLElement) {
        this.datePageButtons = this.container.querySelectorAll(".date-page-button");
        this.items = Array.from(this.container.querySelectorAll(".timeline"));

        this.datePageButtons.forEach((button) => {
            button.addEventListener("click", () => {
                this.setActiveByName(button.value);
            });
        });

        this.setIndex(0);
    }

    private setActiveByName(name) {
        this.items.forEach((item, index) => {
            if (item.classList.contains(name)) {
                this.setIndex(index);
            }
        });
    }

    private setIndex(index: number) {
        this.selectButtonByIndex(index);

        const lastItem = this.items[this.currentIndex];
        lastItem.classList.toggle("current", false);

        this.currentIndex = index;
        const currentItem = this.items[this.currentIndex];

        setTimeout(() => {
            if (lastItem != currentItem) {
                lastItem.classList.toggle("visible", false);
            }
            currentItem.classList.toggle("visible", true);
        }, 300);

        setTimeout(() => {
            currentItem.classList.toggle("current", true);
        }, 600);
    }

    private selectButtonByIndex(index: number) {
        this.datePageButtons.forEach((button, buttonIndex) => {
            if (buttonIndex === index) {
                button.classList.toggle("selected", true);
            } else {
                button.classList.toggle("selected", false);
            }
        });
    }
}
