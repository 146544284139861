export default class HeaderAnimation {
    private header: HTMLElement;
    private main: HTMLElement;

    constructor(header: HTMLElement, main: HTMLElement) {
        this.header = header;
        this.main = main;

        window.addEventListener(
            "scroll",
            () => {
                const scroll = window.scrollY;
                this.header.dataset.scroll = String(scroll);
                this.main.dataset.scroll = String(scroll);
            },
            false
        );
    }
}
