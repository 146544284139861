const expandableLists = document.querySelectorAll(".expandable-list-section");

expandableLists.forEach((list) => {
    const expandableBoxes = list.querySelectorAll(".expandable-box");
    expandableBoxes.forEach((box) => {
        box.querySelector(".title").addEventListener("click", () => {
            box.classList.toggle("expanded");
        });

        const galleryOverlay = box.querySelector(".gallery-overlay");
        if (galleryOverlay) {
            galleryOverlay.querySelector(".close-button").addEventListener("click", () => {
                galleryOverlay.classList.toggle("hidden", true);
            });
            galleryOverlay.addEventListener("click", () => {
                galleryOverlay.classList.toggle("hidden", true);
            });

            box.querySelector(".box-image").addEventListener("click", (e) => {
                galleryOverlay.classList.toggle("hidden");
            });
        }
    });
});
