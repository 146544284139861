export default class ImageGallery {
    private spacing = 25;
    private imageItems: HTMLElement[];
    private imageContainer: HTMLElement;
    private descriptionItems: HTMLElement[];
    private readonly descriptionContainer: HTMLElement;
    private currentIndex = 0;
    private observer: IntersectionObserver;
    private isIntersecting = false;
    private nextButton: HTMLElement;
    private prevButton: HTMLElement;
    private readonly galleryOverlay: HTMLElement | null;

    constructor(private container: HTMLElement) {
        this.imageContainer = <HTMLElement>this.container.querySelector(".image-list");
        if (this.imageContainer) {
            this.imageItems = Array.from(this.imageContainer.querySelectorAll(":scope .image-item:not(.not-matching)"));

            this.descriptionItems = Array.from(this.container.querySelectorAll(".description-item"));
            this.descriptionContainer = <HTMLElement>this.container.querySelector(".description-list");
            this.galleryOverlay = <HTMLElement | null>this.container.querySelector(".gallery-overlay");

            this.nextButton = this.container.querySelector(".next");
            this.nextButton?.addEventListener("click", (e) => {
                this.next();
                e.stopPropagation();
            });
            this.prevButton = this.container.querySelector(".prev");
            this.prevButton?.addEventListener("click", (e) => {
                this.prev();
                e.stopPropagation();
            });

            document.body.addEventListener("keydown", (e) => this.handleKeyPress(e));

            this.imageItems.forEach((image) => {
                image.addEventListener("click", (e) => {
                    if (this.galleryOverlay != null) {
                        this.galleryOverlay.classList.toggle("hidden");
                    }
                    e.stopPropagation();
                });
            });

            if (this.galleryOverlay != null) {
                this.galleryOverlay.querySelector(".close-button").addEventListener("click", () => {
                    this.galleryOverlay.classList.add("hidden");
                });
                this.galleryOverlay.addEventListener("click", () => {
                    this.galleryOverlay.classList.add("hidden");
                });
                window.addEventListener("keydown", (event) => {
                    if (event.key == "Escape") {
                        this.galleryOverlay.classList.add("hidden");
                    }
                });
            }

            this.observer = new IntersectionObserver((entries) => this.handleIntersection(entries), { threshold: 0.5 });
            this.observer.observe(this.container);
            this.setButtonState();
        }
    }

    public reset() {
        this.goTo(0);
        this.imageItems = Array.from(this.container.querySelectorAll(".image-item:not(.not-matching)"));
        this.setButtonState();
    }

    public next() {
        if (this.currentIndex < this.imageItems.length - 1) {
            this.goTo(this.currentIndex + 1);
        }
    }

    public prev() {
        if (this.currentIndex > 0) {
            this.goTo(this.currentIndex - 1);
        }
    }

    public goTo(index: number) {
        if (index < 0 || index >= this.imageItems.length) return;

        this.setIndex(index);
    }

    private handleKeyPress(e: KeyboardEvent) {
        if (!this.isIntersecting) return;

        if (e.key === "ArrowLeft") {
            this.prev();
        } else if (e.key === "ArrowRight") {
            this.next();
        }
    }

    private handleIntersection(entries: IntersectionObserverEntry[]) {
        this.isIntersecting = entries.length >= 1 ? entries[0].isIntersecting : false;
    }

    private setIndex(index: number) {
        this.setCurrentState(this.currentIndex, false);
        this.currentIndex = index;
        this.setCurrentState(index, true);
        const offset = this.getOffsetFor(index);
        this.imageContainer.style.transform = `translateX(-${offset}px)`;
        if (this.descriptionContainer) {
            this.descriptionContainer.style.transform = `translateX(-${offset}px)`;
        }

        this.setButtonState();
    }

    private setButtonState() {
        if (this.currentIndex === 0 && this.imageItems.length === 0) {
            this.toggleButton(true, true);
        } else if (this.currentIndex === 0) {
            this.toggleButton(true, false);
        } else if (this.currentIndex === this.imageItems.length - 1) {
            this.toggleButton(false, true);
        } else {
            this.toggleButton(false, false);
        }
    }

    private toggleButton(prevButton, nextButton) {
        this.prevButton?.classList.toggle("disabled", prevButton);
        this.nextButton?.classList.toggle("disabled", nextButton);
    }

    private setCurrentState(index, state) {
        this.imageItems[index].classList.toggle("current", state);
        if (this.descriptionItems.length > index) {
            this.descriptionItems[index].classList.toggle("current", state);
        }
    }

    private getOffsetFor(index: number) {
        let offset = 0;

        for (let i = 0; i < index; i++) {
            const width = this.imageItems[i].getBoundingClientRect().width;
            offset += width + this.spacing;
        }

        return offset;
    }
}
