export class Form {
    private container: HTMLElement;
    private form: HTMLFormElement;
    private token: string;
    private submitButton: HTMLButtonElement;
    private successMessage: HTMLElement;
    private errorMessage: HTMLElement;
    private errorParagraph: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.form = this.container.querySelector("form");
        if (this.form) {
            this.token = this.form.dataset.token;
            this.submitButton = this.form.querySelector(".submit");
            this.successMessage = document.querySelector(".success-message");
            this.errorMessage = document.querySelector(".error-message");
            this.errorParagraph = this.errorMessage.querySelector(".error-text");

            this.submitButton.addEventListener("click", async (e) => {
                e.preventDefault();
                if (this.form.checkValidity()) {
                    await this.sendRequest();
                } else {
                    this.form.reportValidity();
                }
            });
        }
    }

    private async sendRequest() {
        this.submitButton.disabled = true;
        this.submitButton.classList.add("loading");
        const url = this.form.action;
        const formData = new FormData(this.form);
        if (formData.has("message2") && formData.get("message2") !== "") {
            // Anti Spam
            return;
        }
        formData.delete("message2");

        // combining the different freeform field to the note field
        if (formData.has("note1") || formData.has("note2")) {
            const note1 = formData.get("note1") || "";
            const note2 = formData.get("note2") || "";

            formData.delete("note1");
            formData.delete("note2");
            formData.set("note", `Gewünschter Mietbeginn: ${note1}; Etage: ${note2}`);
        }

        const response = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: this.token,
            },
            body: formData,
        });

        this.submitButton.disabled = false;
        this.submitButton.classList.remove("loading");

        if (response.status !== 200) {
            const result = await response.json();
            console.log(result);
            if (result.result.message) {
                this.errorParagraph.innerHTML = result.result.message;
            }
            this.errorMessage.classList.add("active");
        } else {
            this.successMessage.classList.add("active");
        }
    }
}
