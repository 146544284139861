import Swiper, { Navigation, Keyboard } from "swiper";

const sliders = document.querySelectorAll(".immo-image-section");

const swipers = Array.from(sliders).map((slider) => {
    const viewport = slider.querySelector<HTMLElement>(".viewport");
    const swiper = new Swiper(viewport, {
        modules: [Navigation, Keyboard],
        loop: true,
        wrapperClass: "wrapper",
        slideClass: "slide",
        navigation: {
            nextEl: viewport.querySelector<HTMLElement>(".next"),
            prevEl: viewport.querySelector<HTMLElement>(".prev"),
        },
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
        autoHeight: true,
    });

    let prevIndex = 0;
    const thumbs = Array.from(slider.querySelectorAll(".thumbnail"));

    const updateActiveThumb = () => {
        thumbs[prevIndex].classList.remove("active");
        thumbs[swiper.realIndex].classList.add("active");
        prevIndex = swiper.realIndex;
    };

    swiper.on("slideChange", updateActiveThumb);
    updateActiveThumb();

    thumbs.forEach((t, i) => {
        t.addEventListener("click", () => {
            swiper.slideTo(i);
        });
    });

    return swiper;
});
