import PlausibleEvents from "./PlausibleEvents";
import ImageGallery from "./ImageGallery";
import MilestoneSection from "./MilestoneSection";
import "./NavigationOverlay";
import "./ExpandableBoxes";
import "./TopNav";
import { Form } from "./Form";
import "./WhisperBox";
import "./ImmoSlider";
import HeaderAnimation from "./HeaderAnimation";
import { TopNavHandler } from "./TopNav";
import { VideoSection } from "./VideoSection";
require("fslightbox");

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

const events = new PlausibleEvents();

const galleryContainers = <NodeListOf<HTMLElement>>document.querySelectorAll(".image-slider");
const galleries = Array.from(galleryContainers).map((g) => new ImageGallery(g));

const milestoneSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".milestones-section");
const mileStoneSections = Array.from(milestoneSections).map((m) => new MilestoneSection(m));

const header = <HTMLElement>document.querySelector(".header");
const main = <HTMLElement>document.querySelector("#Main");
if (header && main) {
    const headerAnimation = new HeaderAnimation(header, main);
}

const topNavHandler = new TopNavHandler();

const formContainer = <NodeListOf<HTMLElement>>document.querySelectorAll(".search-form");
formContainer.forEach((formContainer) => {
    new Form(formContainer);
});

const videoSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".youtube-video-section");

videoSections.forEach((videoSection) => {
    new VideoSection(videoSection);
});
