const whisperBox = document.querySelector("#WhisperBox");
const whisperBoxButton = document.querySelector("#WhisperBoxButton");

const toggleWhisperBox = () => {
    whisperBox.classList.toggle("hidden");
    whisperBoxButton.classList.toggle("hidden");
};

whisperBox.querySelector(".close-button").addEventListener("click", () => {
    toggleWhisperBox();
});

whisperBoxButton.addEventListener("click", () => {
    toggleWhisperBox();
});
