const burgerMenu = document.querySelector("#BurgerMenu");
const overlayMenu = document.querySelector("#HeaderNavigation");
const overlayCloseButton = overlayMenu.querySelector("#OverlayCloseButton");

const toggleMenu = () => {
    burgerMenu.classList.toggle("nav-open");
    burgerMenu.classList.toggle("nav-close");
    overlayMenu.classList.toggle("overlay");
};

burgerMenu.addEventListener("click", toggleMenu);
overlayCloseButton.addEventListener("click", toggleMenu);
