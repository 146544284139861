export class VideoSection {
    private container: HTMLElement;
    private modal: HTMLDialogElement;
    private modalYesButton: HTMLButtonElement;
    private modalNoButton: HTMLButtonElement;
    private videos: NodeListOf<HTMLElement>;
    private selectedVideoId: string;

    constructor(container: HTMLElement) {
        this.container = container;
        this.modal = this.container.querySelector(".video-link-modal");
        this.videos = this.container.querySelectorAll(".video-preview");

        this.modalYesButton = this.modal.querySelector(".yes");
        this.modalNoButton = this.modal.querySelector(".no");

        this.videos.forEach((video) => {
            video.addEventListener("click", () => {
                this.selectedVideoId = video.dataset.videoId;
                // @ts-ignore ts doesn't know the showModal function for <dialog> elements
                this.modal.showModal();
            });
        });

        this.modalNoButton.addEventListener("click", () => {
            // @ts-ignore ts doesn't know the close function for <dialog> elements
            this.modal.close();
        });

        this.modalYesButton.addEventListener("click", () => {
            const newTab = window.open(`https://youtube.com/watch?v=${this.selectedVideoId}`, "_blank");

            if (newTab) {
                newTab.focus();
            }
            // @ts-ignore ts doesn't know the close function for <dialog> elements
            this.modal.close();
        });
    }
}
